// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '100'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'bundle_value_f',
    Object: 'value',
    label: '',
    width: '120'
  }, {
    prop: 'price_f',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'expiration_in_days',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'online',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'created_at',
    label: '',
    Object: 'value',
    width: '120'
  }]
}
